<app>
  <router>
    <nav class="navbar navbar-expand-lg navbar-dark navbar-color fixed-nav">
      <a class="navbar-brand" href="/"><img class="navbar-logo" src='/img/logo.svg'></a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
        <span class="mr-auto">&nbsp;</span>
        <div class="navbar-nav">
          <a
          each={page in state.pages}
          class="nav-item nav-link memu-font {state.activePage === page ? 'active' : '' }"
          href={page.path}>
          {page.label}
          <div class="menu-subfont">{page.label_ja}</div>
          </a>
          <span onclick={ scrollSiteTop }>
            <i class="nav-item nav-link memu-font menu-upside-logo far fa-caret-square-up"></i>
          </span>
        </div>
      </div>
    </nav>
    <div class="container site-content">
      <div if={state.showNotFound} class="row">
        <div class="column column-60">
          <not-found />
        </div>
      </div>
      <div if={!state.showNotFound}>
        <route path="/news/:id">
          <news id={ route.params['id'] } />
        </route>
        <route path="/team#:id">
          <team id={ route.params['id'] } isAccesment={state.isAccesment ? state.isAccesment : false}/>
        </route>
        <route path="/policy">
          <policy/>
        </route>
        <route each={page in state.pages} path={page.path}>
          <main is={page.componentName} isAccesment={state.isAccesment ? state.isAccesment : false} about={state.footer.slogan}/>
        </route>
      </div>
    </router>
    <site-footer twitchAccount={state.footer.twitchAccount} twitterAccount={state.footer.twitterAccount} youtubeUrl={state.footer.youtubeUrl}>
  </div>

  <script>
    import { Router, Route, route, toRegexp, match } from '@riotjs/route'
    import lazy from '@riotjs/lazy'
    import Loader from './components/includes/loader/loader.riot'
    import NotFound from './pages/not-found.riot'
    import pages from './pages'

    import axios from 'axios';
    import userEnv from 'userEnv';
    import * as utils from './utils';

    const apiUrl = utils.getApiUrl();

    export default {
      components: {
        Router,
        Route,
        NotFound,
        Home: lazy(Loader, () => import(
          /* webpackPrefetch: true, webpackChunkName: 'pages/home' */
          './pages/home.riot'
        )),
        About: lazy(Loader, () => import(
          /* webpackPrefetch: true, webpackChunkName: 'pages/about' */
          './pages/about.riot'
        )),
        Team: lazy(Loader, () => import(
          /* webpackPrefetch: true, webpackChunkName: 'pages/about' */
          './pages/team.riot'
        )),
        News: lazy(Loader, () => import(
          /* webpackPrefetch: true, webpackChunkName: 'pages/contact' */
          './pages/news.riot'
        )),
        Contact: lazy(Loader, () => import(
          /* webpackPrefetch: true, webpackChunkName: 'pages/about' */
          './pages/contact.riot'
        )),
        Policy: lazy(Loader, () => import(
          /* webpackPrefetch: true, webpackChunkName: 'pages/contact' */
          './pages/policy.riot'
        )),
        Shop: lazy(Loader, () => import(
          /* webpackPrefetch: true, webpackChunkName: 'pages/contact' */
          './pages/shop.riot'
        )),
      },
      state: {
        pages,
        showNotFound: false,
        activePage: null,
        isAccesment: null,
        isNewsActive: false,
        footer: {
          twitterAccount: "",
          slogan: "",
          twitchAccount: "",
          youtubeUrl: ""
        }
      },
      onBeforeMount({ isServer }) {
        // create a stream on all routes to catch the not-found page
        this.anyRouteStream = route('(.*)')
        this.anyRouteStream.on.value(this.onAnyRoute)
      },
      onAnyRoute(path) {
        // show the not found page if none of the page paths are matched
        const activePage = pages.find(p => match(path.pathname, toRegexp(p.path)))
        const newsPage = path.pathname.match('/news\//g')
        const isAccesment = path.hash

        if (!isAccesment) {
          $("html").scrollTop(0);
        }

        this.update({
          activePage,
          showNotFound: !activePage && (!!newsPage),
          isAccesment,
          isNewsActive: !!newsPage,
        });
      },
      async onMounted() {
        let twitterAccount;
        let slogan;
        let twitchAccount;
        let youtubeUrl;
        await axios.get(apiUrl + '/top')
          .then(function (response) {
            slogan = response.data.slogan;
            twitterAccount = response.data.twitter_account;
            twitchAccount = response.data.twitch_account;
            youtubeUrl = response.data.youtube_url
          })
          .catch(function (error) {
              console.log(error);
          });

        const footer = {
          twitterAccount,
          slogan,
          twitchAccount,
          youtubeUrl
        };

        this.update({
          footer
        });
      },
      onBeforeUnmout() {
        this.anyRouteStream.end()
      },
      scrollSiteTop(e) {
        $("html").scrollTop(0);
      }
    }
  </script>
</app>