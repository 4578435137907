/**
 * utils
 * 
 * 2021 clom-networks
 * 
 */

import userEnv from 'userEnv'

export const getApiUrl = () => {
  return userEnv.apiUrl;
};

export const getMovieUrl = () => {
  return userEnv.movieUrl;
};

export const getNowISODate = () => {
  return new Date().toISOString();
};

export const dateFormat = (date) => {
  const localDate = new Date(date);
  return localDate.toLocaleDateString();
};