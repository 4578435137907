<team-member-card>
  <div class="col-md">
      <img class="team-member-image" src={props.image_url}>
      <p class="team-member-name">{props.name}</p>
      <div class="team-member-social-links">
        <span if={ props.twitterAccount }>
          <a class="team-member-social-icon" target="_blank" href="https://twitter.com/{props.twitterAccount}"><i class="fab fa-twitter"></i></a>
        </span>
        <span if={ props.youtubeUrl }>
          <a class="team-member-social-icon" target="_blank" href={ props.youtubeUrl }><i class="fab fa-youtube"></i></a>
        </span>
        <span if={ props.twitchAccount }>
          <a class="team-member-social-icon" target="_blank" href="https://twitch.tv/{ props.twitchAccount }"><i class="fab fa-twitch"></i></a>
        </span>
        <span if={ props.twitcastingAccount }>
          <a class="team-member-social-icon" target="_blank" href="https://twitcasting.tv/{ props.twitcastingAccount }"><img class="team-member-social-logo" src='/img/twitcasting.png'></a>
        </span>
        <span if={ props.mildomAccount }>
          <a class="team-member-social-icon" target="_blank" href="https://mildom.com/{ props.mildomAccount }"><img class="team-member-social-logo" src='/img/mildom.png'></a>
        </span>
      </div>
  </div>
</team-member-card>
