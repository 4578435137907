<team-section-card>
  <div class="col-md">
    <a onclick={scrollTeam} class="team-section">
      <img class="team-section-image" src={props.image_url}>
      <p class="team-section-title">{props.title}</p>
    </a>
  </div>
  <script>
    export default {
      scrollTeam(e) {
        const scroll = $(this.props.teamid).offset().top - 86;
        $("html").scrollTop(scroll);
      }
    }
  </script>
</team-section-card>
