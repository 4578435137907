import { register } from 'riot'
import { basename } from 'path'

const globalComponentsContext = require.context('./components/global/', true, /[a-zA-Z0-9-]+\.riot/)
const topComponentsContext = require.context('./components/top/', true, /[a-zA-Z0-9-]+\.riot/)
const teamComponentsContext = require.context('./components/team/', true, /[a-zA-Z0-9-]+\.riot/)

export default () => {
  globalComponentsContext.keys().map(path => {
    const name = basename(path, '.riot')
    const component = globalComponentsContext(path)

    register(name, component.default)

    return {
      name,
      component
    }
  })

  topComponentsContext.keys().map(path => {
    const name = basename(path, '.riot')
    const component = topComponentsContext(path)

    register(name, component.default)

    return {
      name,
      component
    }
  })

  teamComponentsContext.keys().map(path => {
    const name = basename(path, '.riot')
    const component = teamComponentsContext(path)

    register(name, component.default)

    return {
      name,
      component
    }
  })
}