<team-message>
<div class="team-background">
  <span if={ props.about }>
    <p class="team-message">{ props.about }</p>
  </span>
  <div class="container">
    <div class="row">
      <div class="col-md  top-sponsor-base">
        <sponsor-image each={ sponsor in state.sponsors } company="{sponsor.companyName}" image_url="{sponsor.imageUrl}" site_url="{sponsor.siteUrl}" />
      </div>
  </div>
</div>

<script>
  import axios from 'axios';
  import userEnv from 'userEnv';
  import * as utils from '../../../utils';

  const apiUrl = utils.getApiUrl();
  let self = this;

  export default {
    state: {
      sponsors: ''
    },
    async onMounted(props, state) {
      const sponsors = [];
      await axios.get(apiUrl + '/sponsors')
        .then(function (response) {
          response.data.forEach( data => {
            sponsors.push({
              companyName: data.company_name,
              imageUrl: apiUrl + data.company_image.url,
              siteUrl: data.company_url
            });
          });
        })
        .catch(function (error) {
            console.log(error);
        });
      await this.update({
          sponsors
      });
    },
  }
</script>
</team-message>