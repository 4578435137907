<site-footer>
  <div class="footer-content">
    <span if={ props.twitterAccount }>
      <a class="footer-link-icon" target="_blank" href="https://twitter.com/{props.twitterAccount}"><i class="fab fa-twitter"></i></a>
    </span>
    <span if={ props.youtubeUrl }>
      <a class="footer-link-icon" target="_blank" href={props.youtubeUrl}><i class="fab fa-youtube"></i></a>
    </span>
    <span if={ props.twitchAccount }>
      <a class="footer-link-icon" target="_blank" href="https://twitch.tv/{props.twitchAccount}"><i class="fab fa-twitch"></i></a>
    </span>
    <a class="footer-link-icon" href="/contact"><i class="far fa-envelope"></i></a>
  </div>
  <footer class="site-footer">
    (c) miuna.net Inc 2021
  </footer>
</site-footer>