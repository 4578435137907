<news-card>
  <div class="news-card row">
    <div class="col-md-5 news-card-image">
      <a href="{props.url}">
        <img class="news-image" src="{props.image_url}">
      </a>
    </div>
    <div class="col-md-7">
      <div>
        <a href="{props.url}" class="news-title"><span>{props.title}</span></a>
        <span class="news-date">{props.date}</span>
      </div>
      <div>
        <a href="{props.url}" class="news-description"><p>{props.description}</p></a>
      </div>
    </div>
  </div>
</news-card>